import { render, staticRenderFns } from "./ProReportSubReportTdField.vue?vue&type=template&id=5b42c7ca&scoped=true&"
import script from "./ProReportSubReportTdField.vue?vue&type=script&lang=js&"
export * from "./ProReportSubReportTdField.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b42c7ca",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QDialog});

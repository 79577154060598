<template>
  <div class="subReport_td_field">
    <pro-report
      v-if="markupTableMode"
      :columns="subColumnHeader"
      v-model="value"
      :row-key="subRowKey"
      :loading="loading"
      simpleTableMode
      auto-search
    />
    <div v-else>
      <q-btn
        no-caps
        color="primary"
        :label="getRes('System.Button.View')"
        @click="showReport = true"
      />
      <q-dialog v-model="showReport" full-width>
        <pro-report
          :columns="subColumnHeader"
          v-model="value"
          :row-key="subRowKey"
          :loading="loading"
          simpleTableMode
        />
      </q-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProReportSubReportTdField",
  components: {
    ProReport: () => import("@/components/PROSmart/Report/ProReport"),
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
    subColumnHeader: {
      type: Array,
      required: true,
      default: () => [],
    },
    subRowKey: {
      type: String,
      required: true,
    },

    markupTableMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showReport: false,
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
